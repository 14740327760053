import React from 'react';
import './PortfolioMainImg.scss';
import { navigate } from "@reach/router"


export default class PortfolioMainImg extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            entries: this.props.data,
            useImg: this.props.useImg
        }
        //console.log(this.props.useImg)
    }

    goBack = () => {
        navigate("/")
    }

    componentDidMount(){
      if (this.state.useImg === false){
        this.setState({useImg: this.state.entries[0].mainImg.path})
      }
    }

    // componentWillReceiveProps({useImg}) {
    //   this.setState({...this.state,useImg})
    // }


    render(){
        //const { entries } = this.state;
        const imgApi = this.props.imgapi;

        //console.log(entries);

          return (

            <div className="PortfolioMainImg">
                <div className="back" onClick={this.goBack}>&larr; zur&uuml;ck</div>
                <img src={imgApi + this.state.useImg + '&m=fitToHeight&w=1500&h=1500&o=true'} alt=""/>
            </div>
          );

    }

}
