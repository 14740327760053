import React from 'react';
import './PageContent.scss';
import ContactForm from '../ContactForm/ContactForm';



export default class PageContent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            entries: this.props.data
        }
    }




    componentDidMount(){
        fetch(this.props.baseurl + "api/collections/get/Portfolio?token=" + this.props.token, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              filter: {published: true},
              sort: {order:1}
          })
        })
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                allEntries: result.entries
              });


            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });

            }
          )


    }

    render(){
        return (

           <div className="PageContent">
                <h2>{this.props.data[0].title}</h2>
                <p className="text" dangerouslySetInnerHTML={{__html: this.props.data[0].content}} />
                <ContactForm />
            </div>
          );

    }

}
