import React from 'react';
import './ContactForm.scss';

const baseURL = 'https://backend.pixelbash.de/'
const token = "58c3ea4c5c06b578fadef3edb66276"

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name: '',
            email: '',
            text: '',
            foobar: '',
            submit: false
        }
    }

    submitForm = (event) =>{

        event.preventDefault();

        if(this.state.foobar === ''){
            fetch(baseURL + 'api/forms/submit/Kontakt?token='+token, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    form: {
                        name: this.state.name,
                        email: this.state.email,
                        text: this.state.text,
                        foobar: this.state.foobar
                    }
                })
            })
            .then(entry => entry.json())
            .then(entry => {
                this.setState({submit: true});
            });
        }
        
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }
    
    render() {
        return (
            <div className="formWrapper">
                <form className={"contactForm " + (this.state.submit ? 'submit' : '')} onSubmit={(e) => this.submitForm(e)}>
                    <input type="text" required placeholder="Dein Name" name="name" value={this.state.name} onChange={(e) => this.handleInputChange(e)} />
                    <input type="email" required placeholder="Deine E-Mail Adresse" name="email" value={this.state.email} onChange={(e) => this.handleInputChange(e)} />
                    <input type="text" className="foobar" placeholder="Screenreader: Bitte dieses Feld frei lassen" name="foobar" value={this.state.foobar} onChange={(e) => this.handleInputChange(e)} />
                    <textarea placeholder="Deine Nachricht" required name="text" value={this.state.text} onChange={(e) => this.handleInputChange(e)} />
                    <input type="submit" value="Senden" />
                </form>
                <p className={"text " + (this.state.submit ? 'show' : '')}>Vielen Dank. Ich habe deine Nachricht erhalten und melde mich zeitnah bei dir.</p>
            </div>
        );
    }
}
