import React from 'react';
import HomeGallery from '../../components/HomeGallery/HomeGallery'

export default class Home extends React.Component{

    constructor(props){
        super(props);

        this.state = {}
    }

    render(){
        return(
            <HomeGallery />
        )
    }

}
