import React from 'react';
import { Link } from "@reach/router"
import './PortfolioNav.scss';

export default class PortfolioNav extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            entries: this.props.data
        }
    }

    linkClick = () => {
         //this.props.linkCallback();
    }

    getNextPrev = () => {
        let thisNumber = parseInt(this.props.data[0].order);
        let thisTotal = this.state.allEntries.length;
        let next = thisNumber + 1;
        let prev = thisNumber - 1;

        if (thisNumber + 1 > thisTotal){
            next = 1;
        }
        if (thisNumber === 1){
            prev = thisTotal;
        }

        let nextArr = next - 1;
        let prevArr = prev - 1;

        let thumbNext = this.props.imgapi + this.state.allEntries[nextArr].mainImg.path + '&m=thumbnail&w=100&h=100&o=true';
        let thumbPrev = this.props.imgapi + this.state.allEntries[prevArr].mainImg.path + '&m=thumbnail&w=100&h=100&o=true';

        let titleNext = this.state.allEntries[nextArr].title;
        let titlePrev = this.state.allEntries[prevArr].title;

        let urlNext = this.state.allEntries[nextArr].url;
        let urlPrev = this.state.allEntries[prevArr].url;

        this.setState({
            thumbNext: thumbNext,
            thumbPrev: thumbPrev,
            titleNext: titleNext,
            titlePrev: titlePrev,
            urlNext: urlNext,
            urlPrev: urlPrev

        });

    }

    componentDidMount(){
        fetch(this.props.baseurl + "api/collections/get/Portfolio?token=" + this.props.token, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              filter: {published: true},
              sort: {order:1}
          })
        })
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                allEntries: result.entries
              });

              this.getNextPrev();

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });

            }
          )
    }

    render(){
        return(
            <div className="contentNav">
                <div className="prev">
                    <Link to={'/portfolio/' + this.state.urlPrev} onClick={(e) => this.linkClick(e)}>
                        <img src={this.state.thumbPrev} alt="" />
                        &larr; {this.state.titlePrev}
                    </Link>
                </div>
                <div className="next">
                <Link to={'/portfolio/' + this.state.urlNext} onClick={(e) => this.linkClick(e)}>
                    {this.state.titleNext} &rarr;
                    <img src={this.state.thumbNext} alt="" />
                </Link>
                </div>
            </div>
        )
    }

}
