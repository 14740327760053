import React from 'react';
import './PortfolioGallery.scss';
import Masonry from 'react-masonry-component';

const masonryOptions = {
    transitionDuration: 1
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }


export default class PortfolioGallery extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            entries: this.props.data
        }
    }

    imgClick = (path) => {
        this.props.imgCallback(path);
    }



    componentDidMount(){
        fetch(this.props.baseurl + "api/collections/get/Portfolio?token=" + this.props.token, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              filter: {published: true},
              sort: {order:1}
          })
        })
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                allEntries: result.entries
              });


            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });

            }
          )


    }

    render(){
        const { entries } = this.state;
        const imgApi = this.props.imgapi;

        const imgElements = entries[0].gallery.map((c, i) => {
            let cssClass = "masonry-brick";

            if(c.meta.size === "big"){
                cssClass = "masonry-brick-large";
            }

            return(
                <li key={i} className={cssClass}>
                  <div className="border">
                    <img src={imgApi + c.path + '&m=fitToHeight&w=1000&h=1000&o=true'} alt="" onClick={(e) => this.imgClick(c.path, e)}/>
                  </div>
                </li>
            );
        });

          return (

           <div className="PortfolioGallery">
                <h2>{this.props.data[0].title}</h2>
                <p className="text">{this.props.data[0].teaser}
                    <span className="number">{this.props.data[0].order < 10 ? '0' + this.props.data[0].order : this.props.data[0].order}</span>
                </p>


                  <Masonry
                    className={'my-gallery-class'} // default '' {this.state.urlPrev}
                    elementType={'ul'} // default 'div' {this.state.urlNext}
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                    >
                    {imgElements}
                </Masonry>

            </div>
          );

    }

}
