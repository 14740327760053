import React from 'react';
import Slider from "react-slick";
import { Link } from "@reach/router"

import './HomeGallery.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const baseURL = 'https://backend.pixelbash.de/'
const token = "58c3ea4c5c06b578fadef3edb66276"
const imgApi = baseURL + 'api/cockpit/image?token=' + token + '&src='

class HomeGallery extends React.Component {
  constructor(props) {
    super(props);
    this.handleWheel = this.handleWheel.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      width: window.innerWidth
    };
  }


  componentDidMount() {
    fetch(baseURL + "api/collections/get/Portfolio?token=" + token, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          filter: {published: true},
          sort: {order:1},
      })
    })
      .then(res => res.json())
      .then(
        (result) => {

          this.setState({
            isLoaded: true,
            entries: result.entries
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      document.addEventListener('wheel', this.handleWheel);
      window.addEventListener("resize", this.getWindowSize);
  }


  getWindowSize = () => {
    let winWidth = window.innerWidth;
    this.setState({width: winWidth});
    if (winWidth < 576){
      //this.slider.slickNext()
    }
  }

  componentWillUnmount(){
    document.removeEventListener('wheel', this.handleWheel);
  }

  handleWheel(event) {
    if (this.state.width > 576){
      event.preventDefault()
      event.deltaY > 0 ? this.slider.slickNext() : this.slider.slickPrev()
    }
    
  }

  render() {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      variableWidth: true,
      arrows: true,
      draggable: true,
      swipe: true,
      swipeToSlide: true,
      touchMove: true,
      touchThreshold: 1,
      responsive: [
        {
          breakpoint: 576,
          settings: "unslick"
        }]
    };
    const { error, isLoaded, entries } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {

        if(this.state.width > 567){
          return (
            <div className="homeGallery">
            <Slider {...settings}  id="homeSlider" ref={slider => this.slider = slider && slider['innerSlider']}> 
            {entries.map(pic => (
              <Link to={'portfolio/' + pic.url} key={pic._id}>
              <div className="slide">
    
                  <img src={imgApi + pic.mainImg.path + '&m=fitToHeight&w=1000&h=1000&o=true'} alt=""/>
                  <div className="portInfo">
    
                        <h3>{pic.title}</h3>
                        <p>{pic.teaser}</p>
    
                  </div>
    
              </div>
              </Link>
            ))}
             </Slider>
            </div>
          );
        }else{
          return(
          <div className="homeGallery">
            {entries.map(pic => (
              <Link to={'portfolio/' + pic.url} key={pic._id}>
              <div className="slide">
    
                  <img src={imgApi + pic.mainImg.path + '&m=fitToHeight&w=1000&h=1000&o=true'} alt=""/>
                  <div className="portInfo">
    
                        <h3>{pic.title}</h3>
                        <p>{pic.teaser}</p>
    
                  </div>
    
              </div>
              </Link>
            ))}
            </div>
            );
        }

    }
  }
}

export default HomeGallery;
