import React from 'react';
import { Router, Location, Link } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { ReactComponent as Logo } from './assets/img/logo-schrift.svg';

import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import Page from './pages/Page/Page';

import MainNavi from './components/MainNavi/MainNavi';



const FadeTransitionRouter = props => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          {/* the only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location" */}
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fade: true,
      mobNav: false,
      subNav: false,
      width: window.innerWidth
    }
    window.addEventListener("resize", this.getWindowSize);

  }

  fadeIn = () => {
    setTimeout(() => this.setState({"fade": false}), 200); 
  }

  componentDidMount(){
    this.fadeIn();
  }

  navOpen = () => {
    this.setState({mobNav: !this.state.mobNav})
  }

  toggleSub = () => {
    this.setState({subNav: !this.state.subNav})
  }

  getWindowSize = () => {
    let winWidth = window.innerWidth;
    this.setState({width: winWidth});
  }

  render() {
  return (
    <div className={this.state.fade ? 'App fadeIn' : 'App fadeIn-end'}>
    <header>
      <a href="/"><Logo className="logo" /></a>
      <nav className={(this.state.mobNav ? 'open' : 'close') +' '+ (this.state.subNav ? 'subOpen' : 'subClose')}>
      <button className={"mobileDrop" + (this.state.mobNav ? ' open' : '')} onClick={this.navOpen}>
        <span></span>
        <span></span>
        <span></span>
      </button>
        <ul>
          <li><Link to="/" onClick={this.navOpen}>Home</Link></li>
          <li className={'submenu' } onClick={this.toggleSub}>
            Portfolio 
            <ul className="subnav">
              {(this.state.subNav && this.state.width <= 575 ? <li className="back" onClick={this.toggleSub}>zurück</li> : '')}
              <MainNavi get="Portfolio" sub="portfolio" toggle={this.navOpen} />
            </ul>
          </li>
          <MainNavi get="Pages" sub="pages" toggle={this.navOpen} />
        </ul>
      </nav>
    </header>
    <FadeTransitionRouter>
        <Home path="/" />
        <Portfolio path="/portfolio/:url" />
        <Page path="/pages/:url" />
    </FadeTransitionRouter>
    </div>
  );
}

}

export default App;
